.banner {
  height: 80vh;
  color: ivory;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  scroll-snap-align: start;
}

.simpleContainer {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  scroll-snap-align: start;
}

.projectContainer {
  color: ivory;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 50%;
  margin: 0px auto 0px auto;
}

.projectTitle {
  font-weight: bold;
  font-size: 1.8vh;
  margin: 0px auto 2vh auto;
}

.contentTitle {
  font-weight: bold;
  font-size: 1.5vh;
  margin: 0px auto 1vh auto;
}

.projectContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vh auto 1vh auto;
  font-size: 1.4vh;
  padding: 0 5% 0 5%;
}

.projectTasks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vh auto 1vh auto;
}

.taskTitle {
  font-weight: bold;
  font-size: 1.5vh;
}

.task {
  font-size: 1.4vh;
}

.projectAchievements {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vh auto 1vh auto;
}

.achievementTitle {
  font-weight: bold;
  font-size: 1.5vh;
}

.achievement {
  font-size: 1.4vh;
  padding: 0 5% 0 5%;
}

.projectProfileImageContainer {
  width: 50%;
  height: auto;
  margin: 5vh auto 5vh auto;
}

img {
  width: 100%;
  height: auto;
}

/* size for phone */
@media only screen and (max-width: 600px) {
  .projectProfileImageContainer {
    width: 100%;
  }

  .task {
    font-size: 1.3vh;
  }

  .projectContent {
    font-size: 1.3vh;
  }

  .achievement {
    font-size: 1.3vh;
  }
}

@media only screen and (min-width: 2000px) {
  .projectProfileImageContainer {
    width: 40%;
  }
}

ul {
  list-style-type: none;
  margin: 1vh 0 1vh 0;
}

.hidden {
  opacity: 0;
  transition: all 1s;
}

.show {
  opacity: 1;
}
