.footer {
  width: 100%;
  height: 10vh;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footerLeft {
  color: ivory;
  display: flex;
  align-items: center;
  margin-left: 35px;
}

.footerEmail {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: small;
  font-weight: bold;
  margin-left: 5px;
}

.footerRight {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  color: ivory; 
  display: flex;
  align-items: center;
  margin-right: 35px;
}

.footerLink {
  color: ivory;
  margin: 0px 15px 0px 15px;
}

.footerLink:link {
  text-decoration: none;
}

.footerLink:visited {
  text-decoration: none;
}

.footerLink:hover {
  text-decoration: none;
}

.footerLink:active {
  text-decoration: none;
}