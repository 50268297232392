.skillContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-align: start;
  justify-content: center;
  height: 80vh;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.skillSet {
  color: ivory;
}

.frontendSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ivory;
  margin: 1vh 0 1vh 0;
}

.frontEndHeader {
  font-size: 2vh;
  font-weight: bold;
  margin-bottom: 1vh;
}

.frontendSkill {
  color: ivory;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.backendSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ivory;
  margin: 1vh 0 1vh 0;
}

.backendHeader {
  font-size: 2vh;
  font-weight: bold;
  margin-bottom: 1vh;
}

.backendSkill {
  color: ivory;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.databaseSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ivory;
  margin: 1vh 0 1vh 0;
}

.databaseHeader {
  font-size: 2vh;
  font-weight: bold;
  margin-bottom: 1vh;
}

.databaseSkill {
  color: ivory;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.devOpsSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ivory;
  margin: 1vh 0 1vh 0;
}

.devOpsHeader {
  font-size: 2vh;
  font-weight: bold;
  margin-bottom: 1vh;
}

.devOpsSkills {
  color: ivory;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.devopsSkill {
  color: ivory;
}

.hidden {
  opacity: 0;
  transition: all 1s;
}

.show {
  opacity: 1;
}
