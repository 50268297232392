.homeContent {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  scroll-snap-align: start;
  height: 80vh;
}

.profileAvator {
  margin-top: 4vh;
}

.description {
  color: ivory;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  width: 40%;
  margin: 2vh 0 5vh 2vh;
  font-size: 1.5vh;
}

.description h2 {
  text-align: center;
}

.skillSet {
  color: ivory;
  width: 50%;
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.skillSetHeader {
  font-weight: bold;
  font-size: 1.5vh;
  margin-bottom: 0.5vh;
}

.icons {
  color: ivory;
}
