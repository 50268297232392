.content {
  display: flex;
  flex-direction: column;
}

.contentBody {
  height: 80vh;
  overflow: scroll;
  scroll-snap-type: y mandatory;
}
