.navbar {
  width: 100%;
  height: 10vh;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navLink {
  margin: 0px 10px 0px 10px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  color: ivory; 
}

.navLink:link {
  text-decoration: none;
}

.navlink:visited {
  text-decoration: none;
}

.navLink:hover {
  text-decoration: none;
}

.navLink:active {
  text-decoration: none;
}